import React, { useContext } from "react";
import { Context } from "../store/appContext";
import { Single_noticias_Form_3 } from './single_noticias_form_3';
export const Noticias_Form_3 = () => {
    const { store } = useContext(Context);
    let noticiasFilter = store.noticias;
	var byDate = noticiasFilter;
	byDate.sort(function(a,b) {
		return b.id - a.id;
	});
    return (
        <div className="shadow_news p-1 bg-white">
            <div className="col-12 bb1">
                <h2>Last News</h2>
            </div>
            <div className="row g-0">
                {noticiasFilter.map((item, index) => {
                    if (index < 8) {
                        return (
                            <div key={index} className="col-lg-3 p-2">
                                <Single_noticias_Form_3
                                    id={index}
                                    imagen_principal={item.imagen_principal}
                                    h1={item.h1}
                                    descripcion={item.descripcion}
                                    dia={item.dia}
                                    mes={item.mes}
                                    year={item.year}
                                />
                            </div>
                        );
                    }
                })}
            </div>
        </div>
    )
}
